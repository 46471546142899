/* eslint-disable react/prop-types */

import React, { Component }from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import styles from '../ReviewOrder.module.css';
import CheckBox from '../../../components/CheckBox/CheckBox';
import * as elementActions from '../../../redux/actions/elements';
import { handleFormInput } from '../../../shared/utilities/validations';
import { recipientMessageLength, orderTypeIds, instructionsLength, CONTACTLESS_TEXT, HAND_IT_TO_ME_TEXT } from '../../../shared/constants/order';
import {clearCart} from "../../../shared/utilities/orders";
import endpoints from "../../../shared/constants/endpoints";
import CheckoutContainer from "../../../components/CheckOutContainer/CheckoutContainer";
import * as checkoutActions from "../../../redux/actions/checkout";
import { routeCountryPath } from '../../../shared/utilities/common';

export class Instructions extends Component {
    constructor(props) {
        super(props);

        const { order } = this.props;

        this.state = {
            orderInstructions: order.comment,
            includeSpoon: order.includeSpoon,
            deliveryNotesOpen: true,
            isContactlessDelivery: true
        }
    }

    componentDidMount() {
      this.loadInstructions();
    }

    loadInstructions = () => {
      const { checkout, checkoutUpdated } = this.props;
      const {orderInstructions} = this.state;
      checkout.orderComment = checkout.orderInstructions || orderInstructions;
      this.setState({
        orderInstructions: checkout.orderComment
      })

      checkoutUpdated(checkout);
    }

    handleInput = (e, maxLength) => {
      const {value} = e.target;
      const {checkout, checkoutUpdated} = this.props;

      checkout.orderInstructions = value;
      checkoutUpdated(checkout);
      if (value.length <= maxLength) {
          handleFormInput(e, this, 'orderInstructions')
      }
    };

    setFailedOrder = () => {
      const { component } = this.props;
      clearCart();
      component.props.history.push(routeCountryPath(endpoints.getMenuUrl(null)))
    }

    onExpandDeliveryNotes = (expand) => {
        this.setState({ deliveryNotesOpen: expand });
    }


    setOrderComments = async () => {
        const { orderInstructions } = this.state;
        const { checkout, checkoutUpdated } = this.props;

        checkout.orderComment = orderInstructions;
        checkoutUpdated(checkout);
    }

    setContactlessDelivery = async (value) => {
        const { checkout, checkoutUpdated } = this.props;
        
        this.setState({
            isContactlessDelivery: value
        })
        checkout.isContactlessDelivery = value;
        checkoutUpdated(checkout);
    }

    toggleIncludeSpoon = () => {
        const { includeSpoon } = this.state;
        const { checkout, checkoutUpdated } = this.props;

        this.setState({
            includeSpoon: !includeSpoon
        });

        checkout.includeSpoon = !includeSpoon;
        checkoutUpdated(checkout);
    }

    render () {
        const { orderInstructions, deliveryNotesOpen, includeSpoon, isContactlessDelivery } = this.state;
        const { order, checkout, selectedStore } = this.props;
        const instructionLength = orderInstructions ? orderInstructions.length : 0;
        const isDelivery = parseInt(order.orderTypeId, 10) === orderTypeIds.delivery;
        const maxInstructLength = isDelivery? recipientMessageLength.anotherOrder : instructionsLength.defaultLength;

        const isContactlessVisible = selectedStore?.showContactless;

        if (!isDelivery) return '';
  
        return (
          <CheckoutContainer
            header="Delivery notes"
            subHeader="Enter delivery notes (e.g. security code, building details)"
            subHeaderClass={styles.deliveryNotesSubTitle}
            onExpand={val => this.onExpandDeliveryNotes(val)}
            expanded={deliveryNotesOpen}
            className={styles.minHeight}
          >
            <p
              className={[
                styles.checkoutSectionHeader,
                styles.sectionHeader,
                "text-dark dark:text-white"
              ].join(" ")}
            >
              Delivery Preferences
            </p>

            {isContactlessVisible && (
            <div className="flex flex-col gap-2">
              <p className="flex gap-2">
                <input
                  type="radio"
                  name="isContactlessDelivery"
                  id="handIttoMe"
                  value="No"
                  checked={!isContactlessDelivery}
                  onChange={() => this.setContactlessDelivery(false)}
                />
                {/* eslint-disable-next-line */}
                <label htmlFor="handIttoMe">{HAND_IT_TO_ME_TEXT}</label>
              </p>
              <p className="flex gap-2">
                <input
                  type="radio"
                  name="isContactlessDelivery"
                  id="leaveItAtDoor"
                  value="Yes"
                  checked={isContactlessDelivery}
                  onChange={() => this.setContactlessDelivery(true)}
                />
                {/* eslint-disable-next-line */}
                <label htmlFor="leaveItAtDoor">{CONTACTLESS_TEXT}</label>
              </p>
            </div>
)}
            <Input
              isTextArea
              value={checkout.orderInstructions || orderInstructions}
              handleInput={e => this.handleInput(e, maxInstructLength)}
              handleOnBlur={this.setOrderComments}
              customContainerStyles={styles.inputContainer}
              placeholder=" "
              label="Add instructions for your order"
            />
            <span className={styles.selectionMinor}>
              {`${instructionLength}/${maxInstructLength} characters`}
            </span>
            <div
              className={`${styles.floatRight} w-full flex items-end justify-end`}
            >
              <CheckBox
                onClick={() => this.toggleIncludeSpoon()}
                isChecked={checkout.includeSpoon || includeSpoon}
                label="Include Spoon"
              />
            </div>
          </CheckoutContainer>
        );
    };
}

Instructions.defaultProps = {
    checkout: {},
};

Instructions.propTypes = {
    order: PropTypes.shape().isRequired,
    checkout: PropTypes.shape(),
    checkoutUpdated: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  const { selectedStore } = state.user;
    return {
        order: state.order,
        checkout: state.checkout,
        selectedStore
    }
}

export const mapDispatchToProps = (dispatch) => ({
    checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value)),
    setModalObject: (value) => dispatch(elementActions.setModalObject(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Instructions);