import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./LoyaltyReward.module.css";

import * as loyaltyRepo from "../../shared/repos/graphql/loyalty";
import * as notificationRepo from "../../shared/repos/graphql/customerNotifications";

const mapPin = require("./imgs/map-pin.svg");

class LoyaltyPointTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loyaltyMetrics: [],
      notifications: []
    };
  }

  async componentDidMount() {
    await this.getLoyaltyMetrics();
    await this.getNotifications();
    await this.readNotifications();
  }

  getNotifications = async () => {
    const notifications = await notificationRepo.getNotifications("campaign");

    this.setState({
      notifications: notifications?.data?.getNotifications
    });
  };

  readNotifications = async () => {
    const { notifications } = this.state;
    console.log(notifications);
    if(notifications?.length > 0) {
      const notificationIds = notifications.map(n => parseInt(n.id, 10));
   
      await notificationRepo.readNotifications(notificationIds);
    }    
  };

  getLoyaltyMetrics = async () => {
    const response = await loyaltyRepo.getLoyaltyMetrics();
    this.setState({
      loyaltyMetrics: response.data.loyaltyMetrics
    });
  };

  render() {
    const { points, redeemables } = this.props;
    const { loyaltyMetrics, notifications } = this.state;

    const noRedeemables = !redeemables || redeemables.length <= 0;

    if (noRedeemables) {
      return null;
    }

    let pointsProgress = 0;
    const barProgress = 327 / redeemables.length / 2;

    if (points > redeemables[redeemables.length - 1].points) {
      pointsProgress = 326;
    } else {
      redeemables.forEach((item, index) => {
        const level = index + 1;
        if (!pointsProgress) {
          const userHasPointsLessThenLvlPoints =
            points > 0 && points <= item.points;
          if (userHasPointsLessThenLvlPoints) {
            if (index === 0) {
              pointsProgress = (points / item.points) * barProgress;
            } else {
              pointsProgress =
                ((points - (item.points - redeemables[0].points * level)) /
                  redeemables[0].points) *
                  barProgress +
                barProgress * index;
            }
          }
        }
      });
    }
    
    const notificationMessage = notifications?.length > 0 ? `+${(notifications?.length * 100) } for Referral` : '';

    return (
      <div className={`${styles.topContainer} py-4`}>
        <p
          className="md:hidden w-[375px] h-[84px] text-white 
              py-5 px-4 font-filsonProBold font-bold text-2xl leading-6 tracking-[-0.3px]"
        >
          Rewards
        </p>
        <div
          className={`${styles.loyaltyPoints} mx-auto ly-8 md:py-12 px-4 md:px-8 flex gap-4 items-center bg-cover`}
        >
          <div className="w-[327px] h-[182px] flex flex-col gap-8 md:gap-24">
            <div className="w-full flex flex-col gap-4">
              <p
                className="
                    font-congenialBlack text-4xl leading-9 tracking-[-1px] text-white hidden md:block"
              >
                You have 
                {' '}
                {points}
                <small className="!font-filsonProRegular text-xl leading-[22px] tracking-[-0.3px]">
                  &nbsp;pts
                </small>
              </p>
              <h1
                className="
                    font-congenialBlack text-4xl mt-4 ml-4 leading-9 tracking-[-1px] text-white md:hidden"
              >
                {points}
                <small className="!font-filsonProRegular text-xl leading-[22px] tracking-[-0.3px]">
                  &nbsp;pts
                </small>
                <p className="!text-[#B4A5BB] !leading-5 !text-base !font-[425] !font-filsonProRegular">
                  Rewards Points
                </p>
              </h1>
              <div>
                <p className="text-dryPink">{notificationMessage}</p>
              </div>
            </div>
            <div className="w-full">
              <div className="flex items-center flex-col justify-center absolute">
                <div className="mb-2 w-[327px]">
                  <img
                    src={mapPin}
                    alt={points}
                    style={{ marginLeft: `${pointsProgress - 4}px` }}
                  />
                </div>
                <div className="w-[327px] border-2 border-disabled rounded-[40px]">
                  <div
                    className="border-2 border-glow transition-all w-0 duration-500 ease-in-out"
                    style={{ width: `${pointsProgress}px` }}
                  />
                </div>
                <div className="flex justify-between items-center relative top-[-9px]  w-[327px]">
                  {redeemables.map(item => (
                    <p
                      className={` ${
                        points >= item.points ? "bg-glow" : "bg-dark"
                      } 
                        w-[14px] h-[14px] rounded-full 'border-disabled' border-2 mx-auto`}
                    />
                  ))}
                </div>
                <div
                  className="flex justify-between items-center 
            absolute mt-11 w-[327px] text-white font-filsonProRegular font-[425] text-base leading-5"
                >
                  {redeemables.map(item => (
                    <p className="mx-auto">{item.points}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex gap-4 hidden">
            {loyaltyMetrics.length > 0 &&
              loyaltyMetrics.map(metrics => {
                return (
                  <div className="flex gap-3">
                    <div className={styles.loyaltyPointsCards}>
                      <p>{metrics.value}</p>
                      <p>{metrics.title}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flex gap-2 mt-8 md:hidden mx-auto w-[343px]">
          {loyaltyMetrics.length > 0 &&
            loyaltyMetrics.map(metrics => {
              return (
                <div className="flex gap-3">
                  <div className={styles.loyaltyPointsCards}>
                    <p>{metrics.value}</p>
                    <p>{metrics.title}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

LoyaltyPointTracker.propTypes = {
  points: PropTypes.number,
  redeemables: PropTypes.shape()
};

LoyaltyPointTracker.defaultProps = {
  points: null,
  redeemables: null
};

export default LoyaltyPointTracker;
