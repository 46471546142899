import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

// ROUTER
import { withRouter } from 'react-router-dom';

// REDUX
import { connect } from "react-redux";

import styles from './CmsInlinEdit.module.css';
import * as elementsActions from "../../redux/actions/elements";
import CmsContentEditModal from "./CmsContentEditModal";
import { hasPermission } from "../../shared/utilities/common";
import permissionTypes from "../../shared/constants/permissionTypes";

function CmsInlineEdit({ children, cmsNode, showChilds, showContent, type, permissions, cmsEditMode, setModalObject, markdownClass }) {
  const [prevKey, setPrevKey] = useState(1); // Change key to rerender markdown on update.
  const [updatedContent, setUpdatedContent] = useState(null);
  const canEditCms = hasPermission(
    permissions,
    permissionTypes.cms.category,
    permissionTypes.cms.permissions.edit_values
  );

  const updateContent = (v) => {
    setPrevKey(prevKey + 1);
    setUpdatedContent(v);
  }

  const onEdit = () => {
    setModalObject({
      children: (
        <CmsContentEditModal cmsNode={cmsNode} showChilds={showChilds} onUpdate={(v) => updateContent(v)} showContent={showContent} />
      ),
    });
  };

  const encodeURLsInMarkdown = (markdown) => {
    if (typeof markdown !== 'string') {
      return markdown;
    }

    return markdown.replace(
      /!\[([^\]]+)\]\(([^)]+)\)/g, 
      (match, altText, url) => {
        const encodedUrl = encodeURI(url);  // Encode spaces and special characters
        return `![${altText}](${encodedUrl})`;
      }
    );
  };

  if (!canEditCms || !cmsEditMode) {
    return (
      <>
        { type === 'text' && <ReactMarkdown className={markdownClass} key={prevKey}>{ encodeURLsInMarkdown(children) }</ReactMarkdown>}
        { type !== 'text' && children}
      </>
    );
  }

  return (
    <div className={styles.cmsInlinEdit}>
      <div className={styles.cmsInlinEditHeader}>
        <div className={styles.cmsInlinEditHeaderIcon} onClick={onEdit} aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
        </div>
      </div>
      <div className={styles.cmsInlinEditBody}>
        { type === 'text' && <ReactMarkdown className={markdownClass} key={prevKey}>{ encodeURLsInMarkdown(updatedContent || children) }</ReactMarkdown>}
        { type !== 'text' && children}
      </div>
    </div>
  );
}

export const mapStateToProps = (state) => {
  const {
    permissions,
    cmsEditMode,
  } = state.user;

  return {
    permissions,
    cmsEditMode,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value))
});

CmsInlineEdit.defaultProps = {
  showContent: true,
  showChilds: false,
  type: 'text',
  markdownClass: '',
};

CmsInlineEdit.propTypes = {
  cmsNode: PropTypes.shape({
    value: PropTypes.string.isRequired,
    cmsKeyValueId: PropTypes.string.isRequired,
    cmsKeyId: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }).isRequired,
  showContent: PropTypes.bool,
  showChilds: PropTypes.bool,
  children: PropTypes.node.isRequired,
  permissions: PropTypes.shape({category: String, value: String}).isRequired,
  cmsEditMode: PropTypes.bool.isRequired,
  setModalObject: PropTypes.func.isRequired,
  type: PropTypes.string,
  markdownClass: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CmsInlineEdit));
